import React from 'react'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import { usePrivateRoute } from 'src/utils/auth'
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point'
import { Router, RouteComponentProps } from '@reach/router'

const PlayerProfileAddSuspensionPointsPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players/profile">
      <PlayerProfileAddSuspensionPoints path="/:id" />
    </Router>
  )
}

interface PlayerProfileAddSuspensionPointsRouteProps extends RouteComponentProps {
  id?: string
}

const PlayerProfileAddSuspensionPoints: React.FC<PlayerProfileAddSuspensionPointsRouteProps> = ({
  id
}) => {
  return (
    <Layout>
      <SEO title="Add suspension points" />
      <AddSuspensionPoint />
    </Layout>
  )
}

export default PlayerProfileAddSuspensionPointsPage
